/* eslint-disable max-len */
/* eslint-disable quotes */
import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

import { Button } from '@material-ui/core';

import SinglePageLayout from '../../components/SinglePageLayout';

export const query = graphql`
  query {
    mobileImage: file(relativePath: { eq: "duurzaam/duurzaam-5.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    desktopImage: file(relativePath: { eq: "duurzaam/duurzaam-5.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1300, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    CardHorizontal1Image: file(
      relativePath: { eq: "duurzaam/duurzaam-4.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    CardHorizontal2Image: file(
      relativePath: { eq: "duurzaam/duurzaam-5.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;

const textTop = {
  header: `E S I - Pelletkachel en pellet cv-ketel installatie`,
  textWithLink: () => (
    <>
      <p>
        Pelletkachels zijn relatief nieuw op de Nederlandse markt, maar worden
        al tientallen jaren gebruikt in Scandinavische landen. Deze duurzame
        oplossing brandt op geperste houtkorrels en zorgt voor verwarming én een
        gezellige sfeer in huis. De pelletkachel is verkrijgbaar in vele designs
        en afmetingen. U kunt kiezen voor een kachel die enkel zorgt voor een
        sfeervol vuurtje, één die de woning serieus verwarmt tot een complete
        pellet cv-installatie.
      </p>
    </>
  ),
};

const textBottom = {
  textWithLink: () => (
    <>
      <AniLink fade to='/duurzaam' className='ml-sm-4x'>
        <Button
          role='button'
          variant='contained'
          className='btn btn--secondary heading__button mt-4x'
        >
          <i className='material-icons mr-1x'>link</i>Terug naar overzicht
        </Button>
      </AniLink>
    </>
  ),
};

const Pelletkachel = ({ data, path, location }) => {
  const seo = {
    title: `Pelletkachel`,
    description: `Deze duurzame oplossing van E S I - Installatietechniek B.V. brandt op geperste houtkorrels en zorgt voor verwarming én een gezellige sfeer in huis.`,
    pathname: path,
    location,
  };

  const CardHorizontal1 = {
    noSpace: false,
    img: data.CardHorizontal1Image.childImageSharp.fluid,
    imgAlt: 'E S I - Installatietechniek B.V.',
    imgTitle: 'E S I - Installatietechniek B.V.',
    header: 'Milieuvriendelijk',
    inner:
      'De brandstof voor pelletkachels - houtkorrels - is klimaatvriendelijk. Dit zorgt namelijk voor een lagere CO2-uitstoot dan traditionele HR-ketels. Ook zijn pelletkachels veel schoner en energiezuiniger dan open haarden en gewone houtkachels. De korrels worden gemaakt van snoeihout of zaagsel van houtbedrijven.',
  };

  const CardHorizontal2 = {
    textFirst: true,
    noSpace: true,
    img: data.CardHorizontal2Image.childImageSharp.fluid,
    imgAlt: 'E S I - Installatietechniek B.V.',
    imgTitle: 'E S I - Installatietechniek B.V.',
    header: 'Hoog rendement',
    inner: `De pelletkachel staat bekend om zijn hoge rendement, een gemiddeld toestel heeft al een rendement van 90%. Dit betekent dat 90% van de warmte niet wordt afgevoerd, maar in uw woonkamer wordt geblazen.`,
  };

  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: '(min-width: 970px)',
    },
  ];

  const ribbon = {
    image: sources,
    heading: `E S I - Pelletkachels`,
    pitchLine: `Wordt duurzaam met de pelletkachels van E S I – Installatietechniek B.V.`,
  };

  return (
    <>
      <SinglePageLayout
        seo={seo}
        ribbon={ribbon}
        textTop={textTop}
        CardHorizontal1={CardHorizontal1}
        CardHorizontal2={CardHorizontal2}
        textBottom={textBottom}
      />
    </>
  );
};

Pelletkachel.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
  path: PropTypes.string,
};

export default Pelletkachel;
